<template>
  <div class="card largebox-Container border-0">
    <div class="card-body p-0 border-0">
      <div>
        <div class="d-flex flex-column text-left emailAddresses-Row">
          <div class="emailAddresses-titleHolder border-0">
            <span class="emailAddresses-Title">Email Type</span>
            <span>
              <v-icon
                id="geographic-help-icon"
                color="#2C9A65"
                small
                style="left: 8px"
                class="review-Helpbutton-Container"
                >mdi-information-outline</v-icon
              >
              <b-tooltip target="geographic-help-icon" placement="bottom">
                A breakdown of the locations in your contact data based on their
                address given.
              </b-tooltip></span
            >
          </div>
          <div id="chart">
            <apexchart
              height="220px"
              width="100%"
              type="pie"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "geographiclocation",
  props: {
    currentTask: Object,
  },
  data() {
    return {
      dataItems: [],
      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        legend: {
          fontFamily: "Poppins",
          fontSize: "10px",
        },

        plotOptions: {
          pie: {
            dataLabels: {
              offset: -10,
            },
          },
        },
        stroke: {
          width: 0,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val, opts) {
              let total = opts.globals.seriesTotals.reduce((a, b) => a + b, 0);
              let percentage = ((val / total) * 100).toFixed(0);
              return `${percentage}%`;
            },
          },
        },
        fill: {
          colors: [
            "#8E44AD",
            "#1ABC9C",
            "#34495E",
            "#BDC3C7",
            "#C0392B",
            "#F39C12",
            "#3498DB",
            "#2980B9",
            "#7F8C8D",
            "#E74C3C",
            "#2C3E50",
            "#E67E22",
            "#D35400",
          ],
        },

        labels: ["Personal", "Generic"],
        colors: [
          "#8E44AD",
          "#1ABC9C",
          "#34495E",
          "#BDC3C7",
          "#C0392B",
          "#F39C12",
          "#3498DB",
          "#2980B9",
          "#7F8C8D",
          "#E74C3C",
          "#2C3E50",
          "#E67E22",
          "#D35400",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(0) + "%";
          },
          style: {
            fontSize: "10px",
            fontWeight: 600,
            colors: ["#ffffff"],
          },
          dropShadow: {
            enabled: false,
          },
          textAnchor: "middle",
          offsetX: 5,
          offsetY: 5,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    showRecords() {
      var list = this.dataItems;
      return list;
    },
  },
  mounted() {
    this.calculateEmailTypes();
  },
  methods: {
    calculateEmailTypes() {
      var result = JSON.parse(this.currentTask.results);
      var totalRecords = parseInt(result.noOfEmailRecords);

      // Calculate raw values for personal and generic emails
      let personal =
        (parseInt(result.noOfEmailTypePersonal) / totalRecords) * 100;
      let generic =
        (parseInt(result.noOfEmailTypeGeneral) / totalRecords) * 100;

      // Normalize the values so that the sum is exactly 100%
      let totalPercentage = personal + generic;

      if (totalPercentage !== 100) {
        // Adjust the largest percentage to make sure the sum is exactly 100
        let maxValue = Math.max(personal, generic);
        let adjustment = 100 - totalPercentage;

        // Apply adjustment to the largest value
        if (maxValue === personal) personal += adjustment;
        else if (maxValue === generic) generic += adjustment;
      }

      // Update the series with personal and generic percentages
      this.series = [personal, generic];
    },
    personal() {
      var result = JSON.parse(this.currentTask.results);
      var totalRecords = result.noOfEmailRecords;
      var personal = (
        (parseInt(result.noOfEmailTypePersonal) / totalRecords) *
        100
      ).toFixed(0);
      return personal;
    },
    generic() {
      var result = JSON.parse(this.currentTask.results);
      var totalRecords = result.noOfEmailRecords;
      var generic = (
        (parseInt(result.noOfEmailTypeGeneral) / totalRecords) *
        100
      ).toFixed(0);
      return generic;
    },
  },
};
</script>

<style>
.emailAddresses-Container {
  min-height: 400px;
}

.emailAddresses-Title {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657d;
}

.emailAddresses-Count {
  font-size: 43px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
}

.emailAddresses-Summary {
  font-weight: 400;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  color: #3d415b;
}

.emailAddresses-Row {
  width: 100%;
  height: 39px;
  margin-top: 25px;
  margin-left: 10px;
}

.emailAddresses-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.emailAddresses-chartHolder {
  width: 40%;
  margin-left: 30%;
}

.emailAddresses-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.progress-bar {
  background-color: #147ad6 !important;
}
</style>
