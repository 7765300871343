<template>
  <div class="border-0">
    <div class="mt-7 pb-0">
      <div class="">
        <div class="d-flex flex-column text-center estimateFields-Row">
          <div class="estimateFields-titleHolder">
            <span style="display: inline-flex; align-items: center">
              <span class="estimateFields-Title">Cost of Data Cleanse</span>
              <v-icon
                id="estimateFields-help-icon"
                color="#2C9A65"
                small
                style="left: 8px"
                class="review-Helpbutton-Container"
                >mdi-information-outline</v-icon
              >
            </span>
            <b-tooltip target="estimateFields-help-icon" placement="bottom">
              The estimated price to download your cleansed data, please visit
              our website to view how we calculate our audits. To purchase your
              data please click 'Purchase your data' above
            </b-tooltip>
          </div>

          <div>
            <span class="estimateFields-Count">£&nbsp;</span>
            <span class="estimateFields-Count">{{ estimateFields() }}</span>
            <!-- <animate-number style="font-size: 24px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.33;
                  color: #2C9A65;" 
                  from="0" 
                  duration="1000" 
                  :to=estimateFields()
                  :format="formatNumber"
                  :precision="2"
                  >
              pound
            </animate-number> -->
          </div>
          <!-- <span class="estimateFields-Count">{{estimateFields()}}</span> -->

          <!-- <b-row class="estimateFields-Row estimateFields-Row-underline">
              <b-col>
                <span class="estimateFields-Summary">Records Improved</span>
              </b-col>
              <b-col>
                <span class="estimateFields-Summary">{{improved()}}</span>
              </b-col>
          </b-row>
          <b-row class="estimateFields-Row estimateFields-Row-underline">
              <b-col>
                <span class="estimateFields-Summary">Missing Information</span>
              </b-col>
              <b-col>
                <span class="estimateFields-Summary">{{estimateFields()}}</span>
              </b-col>
          </b-row> -->
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
// import SimpleTables from '../../../pages/vuetify/tables/SimpleTables.vue';

export default {
  components: {
    // SimpleTables
  },
  name: "estimateFields",
  props: {
    currentTask: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {},
  methods: {
    estimateFields() {
      var retVal = 0;
      // var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = this.currentTask.estprice;
      retVal = parseFloat(
        retVal.replace("£", "").replace(",", "").replace(" ", "")
      );
      return retVal.toFixed(2);
    },
    formatNumber(number) {
      return number.toFixed(2);
    },
    improved() {
      var retVal = "";
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noChangedRecords);
      return retVal;
    },
  },
};
</script>

<style>
.estimateFields-Title {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657d;
  white-space: nowrap;
}

.estimateFields-Count {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #2c9a65;
}

.estimateFields-Summary {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #3f6f86;
  opacity: 0.8;
}

.estimateFields-Row {
  width: 100%;
}

.estimateFields-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.estimateFields-Row {
  width: 100%;
}

.estimateFields-titleHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  white-space: nowrap;
}

.estimateFields-titleHolder span {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0;
}
</style>
