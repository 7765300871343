<template>
  <div class="border-0">
    <div class="card-body" style="padding: 10px !important">
      <div class="">
        <div class="d-flex flex-column text-left">
          <div class="contactBase-titleHolder border-0">
            <span class="contactBase-Title">Contacts</span>
            <span>
              <v-icon
                id="contactNames-help-icon"
                color="#2C9A65"
                small
                style="left: 6px"
                class="review-Helpbutton-Container"
                >mdi-information-outline</v-icon
              >
              <b-tooltip target="contactNames-help-icon" placement="bottom">
                The number of individual records which have contact name
                improvements
              </b-tooltip></span
            >
          </div>

          <!-- <div><br/>
            <v-icon
              id="contactBase-help-icon"
              small
            class="review-Helpbutton-Container">mdi-help-circle-outline</v-icon>
            <b-tooltip target="contactBase-help-icon" placement="bottom">
              Broken down to show Valid, Invalid and Blank Email Addresses
            </b-tooltip>
          </div> -->
          <!-- <div class="row">
            <div class="col-sm-4 contactBase-chartHolder">
            <donut-chart :chart-data="chartdata" :options="options"></donut-chart>
          </div>
         
           </div> -->

          <!-- <div class="progress blue">
                <span class="progress-left">
                    <span class="progress-bar"></span>
                </span>
                <span class="progress-right">
                    <span class="progress-bar"></span>
                </span>
                <div class="progress-value">{{validPercentage()}}%</div>
            </div> -->
          <br />

          <div class="d-flex">
            <div class="col-xl-5 p-0">
              <div>
                <div class="mb-2">
                  <b-row>
                    <b-col>
                      <span class="summarydata">Imported: </span>
                      <span class="summarycount">{{ totalRecords() }}</span>
                    </b-col>
                  </b-row>
                </div>

                <div class="mb-2">
                  <b-row>
                    <b-col>
                      <span class="summarydata">Improved: </span>
                      <span class="summarycount" style="color: #33b985">{{
                        improved()
                      }}</span>
                    </b-col>
                  </b-row>
                </div>

                <div class="mb-2">
                  <b-row>
                    <b-col>
                      <span class="summarydata">Duplicates: </span>
                      <span class="summarycount" style="color: #dc3545">{{
                        dupicateCount()
                      }}</span>
                    </b-col>
                  </b-row>
                </div>

                <div class="mb-2">
                  <b-row>
                    <b-col>
                      <span class="summarydata">Blank or Null: </span>
                      <span class="summarycount">{{ missing() }}</span>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
            <!-- <div class="col-xl-4">
              <vue-ellipse-progress
                :progress="validPercentage()"
                fontSize="25px"
                fontweight="500"
                :size="130"
                color="#2C9A65"
                :thickness="10"
              >
                <span slot="legend-value">%</span>
                <p slot="legend-caption">Improved</p>
              </vue-ellipse-progress>
            </div>
            <div class="col-xl-4">
              <vue-ellipse-progress
                :progress="duplicates()"
                fontSize="25px"
                fontweight="500"
                :size="120"
                color="#2C9A65"
                :thickness="10"
              >
                <span slot="legend-value">%</span>
                <p slot="legend-caption">Duplicates</p>
              </vue-ellipse-progress>
            </div> -->

            <div class="col-xl-7 p-0" style="margin: -10px">
              <div class="d-flex align-items-center justify-content-around">
                <div class="pr-2">
                  <vue-ellipse-progress
                    :progress="validPercentage()"
                    fontSize="15px"
                    fontweight="500"
                    :size="100"
                    color="#2C9A65"
                    :thickness="8"
                  >
                    <span slot="legend-value">%</span>
                    <p slot="legend-caption">Improved</p>
                  </vue-ellipse-progress>
                </div>
                <div class="">
                  <vue-ellipse-progress
                    :progress="duplicates()"
                    fontSize="15px"
                    fontweight="500"
                    :size="100"
                    color="#dc3545"
                    :thickness="8"
                  >
                    <span slot="legend-value">%</span>
                    <p slot="legend-caption">Duplicates</p>
                  </vue-ellipse-progress>
                </div>
              </div>
            </div>
          </div>

          <!-- <div style="margin-left: 26px; margin-top: -3px;">			
              <vue-ellipse-progress 
                  :progress=validPercentage()
                  fontSize="30px"
                  fontweight="500" 
                  :size="150"
              >
              <span slot="legend-value">%</span>
              <p slot="legend-caption">Improved</p>    
              </vue-ellipse-progress>
          </div> -->

          <!-- <div style=" margin-top: -10%;">
              <span class="percentage">{{validPercentage()}}% </span>
              <span style=" color: #3f6f86; margin-left: -12px; font-size: 14px; font-weight: 300;">Validated</span>
            </div> -->

          <!-- <br> -->
          <!-- <b-row class="contactBase-Row">
            <b-col cols="4" style="margin-bottom: 10px;text-align: right;">
              <span class="contactBase-Summary" >{{totalRecords()}}</span>
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">
                <span class="contactBase-Summary" style="color:#3f6f86;">Imported</span>
                
              </b-col>
              <b-col cols="4" style="margin-bottom: 10px; text-align: right;">
                <span class="contactBase-Summary" >{{valid()}}</span>
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">
               
                <span class="contactBase-Summary" style="color:#33b985;">Improved</span>
              </b-col>
              <b-col cols="4" style="margin-bottom: 10px;text-align: right;">
                <span class="contactBase-Summary" style="text-align: right;">{{invalid()}}</span>
                
              </b-col>
              <b-col  cols="1"></b-col>
              <b-col cols="6">
                <span class="contactBase-Summary" style="color:#black;">Unchanged/Blank</span>

              </b-col>
          </b-row> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import DonutChart from './DonutChart.js'

export default {
  name: "contactNames",
  props: {
    currentTask: Object,
  },
  components: {
    // DonutChart
  },
  data() {
    return {
      // chartdata: {
      //     labels: ["Imported", "Valid", "Invalid"],
      //     datasets: [
      //         {
      //         label: "Data One",
      //         backgroundColor: ["#3f6f86",  "#33b985" ,"#ec6666"],
      //         data: [this.totalRecords(), this.valid(), this.invalid()]
      //         }
      //     ],
      // },
      options: {
        legend: {
          display: false,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.fillData();
  },
  methods: {
    totalRecords() {
      var retVal = "";
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfContactRecords;
      return retVal;
    },
    contactNames() {
      var retVal = "";
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = currentTaskResults.noOfContactRecords;
      if (parseInt(currentTaskResults.noOfContactRecords) == 0) {
        retVal = 0;
      }
      return retVal;
    },
    fillData() {
      this.datacollection = {
        labels: [this.getRandomInt(), this.getRandomInt()],
        datasets: [
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [this.getRandomInt(), this.getRandomInt()],
          },
          {
            label: "Data One",
            backgroundColor: "#f87979",
            data: [this.getRandomInt(), this.getRandomInt()],
          },
        ],
      };
    },
    getRandomInt() {
      return Math.floor(Math.random() * (50 - 5 + 1)) + 5;
    },
    dupicateCount() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var retVal = parseInt(currentTaskResults.noOfContactDups);
      if (parseInt(currentTaskResults.noOfContactRecords) == 0) {
        retVal = 0;
      }
      return retVal;
    },
    improved() {
      var retVal = "";
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noOfContactsImproved);
      if (parseInt(currentTaskResults.noOfContactRecords) == 0) {
        retVal = 0;
      }
      return retVal;
    },
    // duplicates() {
    //   var result = JSON.parse(this.currentTask.results);
    //   var dubs = parseFloat(result.noOfContactDupsRatio) * 100;
    //   return dubs.toFixed(2);
    // },
    duplicates() {
      var retVal = 0;
      var result = JSON.parse(this.currentTask.results);
      var dubs = parseInt(result.noOfContactDups);

      var totalRecords = result.noOfContactRecords;
      retVal = ((dubs / totalRecords) * 100).toFixed(1);

      if (parseInt(dubs) == 0) {
        retVal = 0;
      }
      return retVal;
    },
    valid() {
      var retVal = "";
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noOfContactsValid);
      if (parseInt(currentTaskResults.noOfContactRecords) == 0) {
        retVal = 0;
      }
      return retVal;
    },
    missing() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var retVal = parseInt(currentTaskResults.noOfContactsBlank);
      if (parseInt(currentTaskResults.noOfContactRecords) == 0) {
        retVal = 0;
      }
      return retVal;
    },
    validPercentage() {
      var retVal = "";
      var validated = JSON.parse(this.currentTask.results);
      validated = parseInt(validated.noOfContactsImproved);
      var totalRecords = JSON.parse(this.currentTask.results);
      totalRecords = totalRecords.noOfContactRecords;
      retVal = ((validated * 100) / totalRecords).toFixed(1);
      // return ((validated / 100)).toFixed(0)
      if (parseInt(validated.noOfContactRecords) == 0) {
        retVal = 0;
      }

      return retVal;
    },
    missingPercentage() {
      var retVal = "";
      var validated = JSON.parse(this.currentTask.results);
      validated = parseInt(validated.noOfContactsBlank);
      var totalRecords = JSON.parse(this.currentTask.results);
      totalRecords = totalRecords.noOfContactRecords;
      retVal = ((validated * 100) / totalRecords).toFixed(1);
      // return ((validated / 100)).toFixed(0)
      if (parseInt(validated.noOfContactRecords) == 0) {
        retVal = 0;
      }

      return retVal;
    },
  },
};
</script>

<style>
.contactBase-Container {
  min-height: 400px;
}

.contactBase-Title {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657d;
}

.contactBase-Count {
  font-family: "Poppins";
  font-size: 43px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
}

.contactBase-Summary {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  color: #3d415b;
  opacity: 0.8;
}

.contactBase-Row {
  width: 100%;
}

/* .contactBase-Row-underline {
    border-bottom: solid 1px #e9eef3;
} */
.contactBase-chartHolder {
  width: 35%;
  margin-left: 30%;
}

.contactBase-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.percentage {
  font-family: "Poppins";
  font-size: 120px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
  margin-left: 3px;
}
</style>
