<template>
  <div class="border-0">
    <div class="card-body">
      <div class="">
        <div class="d-flex flex-column text-left">
          <div class="genderTypes-titleHolder border-0">
            <span class="genderTypes-Title">Salutations Breakdown</span>
            <span>
              <v-icon
                small
                id="genderTypes-help-icon"
                color="#2C9A65"
                style="left: 8px"
                class="review-Helpbutton-Container"
                >mdi-information-outline</v-icon
              >
              <b-tooltip target="genderTypes-help-icon" placement="bottom">
                A breakdown of the salutation provided within your contact data
              </b-tooltip>
            </span>
          </div>
          <div>
            <apexchart
              type="bar"
              height="190"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
          <!-- <div v-for="(item, i) in showRecords" v-bind:key="i">
            <b-row class="genderTypes-Row">
              <b-col>
                <span class="genderTypes-Summary" style="float: right;">{{ item.amount }}%</span>
                <VueJsProgress class="genderTypes-Summary" :title=item.name :percentage=item.amount customBgColor="#2C9A65"  
                  :delay="600" :striped="true" show-text :animation="true"></VueJsProgress>
              </b-col>
            </b-row>
          </div> -->

          <!-- <vue-bar-graph :points="points" :width="400" :labels="labels" :height="200" /> -->
          <!-- <vue-bar-graph :points=[1,4,5,3,4,6,7,8,9,10,11,12] :show-y-axis="false" :show-x-axis="true" :width="400" :height="200"
            :show-values="true" :use-custom-labels="true"
            :labels="['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dec']" /> -->

          <!-- <bar-chart :data="[['X-Small', 5], ['Small', 27]]"></bar-chart> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import VueJsProgress from 'vue-js-progress'
// import VueBarGraph from 'vue-bar-graph';

export default {
  name: "genderTypes",
  components: {
    // VueJsProgress,
    // VueBarGraph,
  },
  props: {
    currentTask: Object,
  },
  data() {
    return {
      // dataItems: [
      //   {
      //     name: 'Male',
      //     amount: this.male(),
      //   },
      //   {
      //     name: 'Female',
      //     amount: this.female(),
      //   },
      //   {
      //     name: 'Unknown',
      //     amount: this.unknown(),
      //   },
      // ]
      series: [
        {
          name: "Count",
          data: [this.created(), this.blank()],
        },
      ],
      chartOptions: {
        chart: {
          height: 250,
          type: "bar",
          width: 100,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        legend: {
          show: false,
          position: "right",
        },
        colors: ["#2980B9", "#2C3E50", "#1ABC9C"],
        tooltip: {
          enabled: true,
          y: {
            formatter: (val, opts) => {
              const dataPointIndex = opts.dataPointIndex;
              if (dataPointIndex === 0) {
                return this.createdCount();
              } else if (dataPointIndex === 1) {
                return this.blankCount();
              }
              return val;
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -30,
          style: {
            fontSize: "14px",
            colors: ["#2980B9", "#2C3E50"],
          },
        },
        xaxis: {
          categories: ["Created", "Blank or Null"],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          // crosshairs: {
          //   fill: {
          //     // type: 'gradient',
          //     gradient: {
          //       colorFrom: '#D8E3F0',
          //       colorTo: '#BED1E6',
          //       opacityFrom: 0.4,
          //       opacityTo: 0.2,
          //     }
          //   }
          // },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            // formatter: function (val) {
            //   return val + "%";
            // }
          },
        },
        // title: {
        //   text: 'Monthly Inflation in Argentina, 2002',
        //   floating: true,
        //   offsetY: 330,
        //   align: 'center',
        //   style: {
        //     color: '#444'
        //   }
        // }
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    showRecords() {
      var list = this.dataItems;
      //return list.sort((a, b) => (a.amount < b.amount) ? 1 : -1).slice(0, 6);
      return list;
    },
  },
  mounted() {
    //this.fillData()
  },
  methods: {
    maxRecords() {
      var count = 0;
      this.dataItems.forEach((dataItem) => {
        count = count + parseInt(dataItem.amount);
      });
      return count;
    },

    created() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var create = parseInt(
        currentTaskResults.noOfContactsSalutationSet
      ).toFixed(2);

      var total_count = parseInt(currentTaskResults.noOfRecords).toFixed(2);

      var retVal = ((create / total_count) * 100).toFixed(2);
      return retVal;
    },
    blank() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var blank =
        parseInt(currentTaskResults.noOfRecords) -
        parseInt(currentTaskResults.noOfContactsSalutationSet);
      var total_count = parseInt(currentTaskResults.noOfRecords).toFixed(2);
      var retVal = ((blank / total_count) * 100).toFixed(2);
      return retVal;
    },
    createdCount() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var create = parseInt(currentTaskResults.noOfContactsSalutationSet);
      return create;
    },
    blankcount() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var blank =
        parseInt(currentTaskResults.noOfRecords) -
        parseInt(currentTaskResults.noOfContactsSalutationSet);
      return blank;
    },
    // female() {
    //   var currentTaskResults = JSON.parse(this.currentTask.results);
    //   var female = parseInt(currentTaskResults.noFemales).toFixed(2);
    //   var total_count =
    //     parseInt(currentTaskResults.noMales) +
    //     parseInt(currentTaskResults.noFemales) +
    //     parseInt(currentTaskResults.noGenderUnknown);
    //   var retVal = ((female / total_count) * 100).toFixed(2);
    //   return retVal;
    // },
    // unknown() {
    //   var currentTaskResults = JSON.parse(this.currentTask.results);
    //   var unknown = parseInt(currentTaskResults.noGenderUnknown).toFixed(2);
    //   var total_count =
    //     parseInt(currentTaskResults.noMales) +
    //     parseInt(currentTaskResults.noFemales) +
    //     parseInt(currentTaskResults.noGenderUnknown);
    //   var retVal = ((unknown / total_count) * 100).toFixed(2);
    //   return retVal;
    // }
  },
};
</script>

<style>
.genderTypes-Container {
  min-height: 200px;
}

.genderTypes-Title {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657d;
}

.genderTypes-Count {
  font-size: 43px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
}

.genderTypes-Summary {
  font-weight: 400;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  color: #3d415b;
}

.genderTypes-Row {
  width: 100%;
}

.genderTypes-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.genderTypes-chartHolder {
  width: 40%;
  margin-left: 30%;
}

.genderTypes-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.progress-bar {
  background-color: #147ad6 !important;
}
</style>
