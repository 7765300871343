<template>
  <div class="border-0">
    <div class="mt-7 pb-0">
      <div class="">
        <div class="d-flex flex-column text-center estimateFields-Row">
          <div class="estimateFields-titleHolder">
            <span class="estimateFields-Title">Score</span>
            <span>
              <v-icon
                id="score-help-icon"
                color="#2C9A65"
                small
                style="left: 8px"
                class="review-Helpbutton-Container"
                >mdi-information-outline</v-icon
              >
              <b-tooltip target="score-help-icon" placement="bottom">
                We score your cleaned data based on the overall quality and
                improvements we were able to make.
              </b-tooltip></span
            >
          </div>

          <div>
            <!-- <span class="estimateFields-Count">&nbsp;</span> -->
            <span class="estimateFields-Count">{{ score() }}</span>
            <!-- <span class="estimateFields-Count">{{ estimateFields() }}</span> -->
            <!-- <animate-number style="font-size: 24px;
                  font-weight: 600;
                  font-stretch: normal;
                  font-style: normal;
                  line-height: 1.33;
                  color: #2C9A65;" 
                  from="0" 
                  duration="1000" 
                  :to=estimateFields()
                  :format="formatNumber"
                  :precision="2"
                  >
              pound
            </animate-number> -->
          </div>
          <!-- <span class="estimateFields-Count">{{estimateFields()}}</span> -->

          <!-- <b-row class="estimateFields-Row estimateFields-Row-underline">
              <b-col>
                <span class="estimateFields-Summary">Records Improved</span>
              </b-col>
              <b-col>
                <span class="estimateFields-Summary">{{improved()}}</span>
              </b-col>
          </b-row>
          <b-row class="estimateFields-Row estimateFields-Row-underline">
              <b-col>
                <span class="estimateFields-Summary">Missing Information</span>
              </b-col>
              <b-col>
                <span class="estimateFields-Summary">{{estimateFields()}}</span>
              </b-col>
          </b-row> -->
        </div>
      </div>
    </div>
  </div>
  <!-- </div> -->
</template>

<script>
import { mapGetters } from "vuex";
// import SimpleTables from '../../../pages/vuetify/tables/SimpleTables.vue';

export default {
  components: {
    // SimpleTables
  },
  name: "estimateFields",
  props: {
    currentTask: Object,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {},
  methods: {
    score() {
      var retVal = 0;
      // var currentTaskResults = JSON.parse(this.currentTask.results);
      var result = JSON.parse(this.currentTask.results);

      retVal = parseFloat(result.noOfCleanedScore);
      if (retVal < 0.01) {
        return "F";
      } else if (retVal <= 0.02) {
        return "D";
      } else if (retVal <= 0.49) {
        return "C";
      } else if (retVal <= 0.74) {
        return "B";
      } else if (retVal <= 0.89) {
        return "A";
      } else if (retVal <= 1.0) {
        return "A+";
      } else {
        return "N/a";
      }
    },
    formatNumber(number) {
      return number.toFixed(2);
    },
    improved() {
      var retVal = "";
      var currentTaskResults = JSON.parse(this.currentTask.results);
      retVal = parseInt(currentTaskResults.noChangedRecords);
      return retVal;
    },
  },
};
</script>

<style>
.estimateFields-Title {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 17px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657d;
}

.estimateFields-Count {
  font-family: "Poppins";
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #2c9a65;
}

.estimateFields-Summary {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  color: #3f6f86;
  opacity: 0.8;
}

.estimateFields-Row {
  width: 100%;
}

.estimateFields-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.estimateFields-Row {
  width: 100%;
}

.estimateFields-titleHolder {
  padding-bottom: 10px;
}
</style>
