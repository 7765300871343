<template>
  <div class="card largebox-Container border-0">
    <div class="card-body p-0 border-0">
      <div class="">
        <div class="d-flex flex-column text-left emailAddresses-Row">
          <div class="emailAddresses-titleHolder border-0">
            <span class="emailAddresses-Title">Phone Types</span>
            <span>
              <v-icon
                id="phonetype-help-icon"
                small
                style="left: 8px"
                class="review-Helpbutton-Container"
                >mdi-information-outline</v-icon
              >
              <b-tooltip target="phonetype-help-icon" placement="bottom">
                A breakdown of the phone types provided within your contact data
              </b-tooltip></span
            >
          </div>
          <div id="chart">
            <apexchart
              height="220px"
              width="100%"
              type="pie"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
          <!-- <div v-for="(item, i) in showRecords" v-bind:key="i">
                <b-row class="emailAddresses-Row">
                    <b-col>
                      <span class="show-percentage" style="float: right;">{{ item.amount }}%</span>
                      <VueJsProgress  :title=item.name  :percentage=item.amount customBgColor="#2C9A65"   :delay="600" :striped="true" show-text :animation="true"></VueJsProgress >
                    </b-col>
                  </b-row>
                </div> -->

          <!-- <b-col>
                        <span class="emailAddresses-Summary">{{item.name}}</span>
                    </b-col>
                    <b-col  style="text-align:right">
                        <span class="emailAddresses-Summary">{{item.amount}}</span>
                    </b-col> -->

          <!-- <b-row class="emailAddresses-Row">
                    <b-col>
                        <b-progress :value="item.amount" :max="maxRecords()" class="mb-3" style="background-color:rgba(115, 136, 169, 0.35);"></b-progress>
                    </b-col>
                </b-row> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import VueJsProgress from 'vue-js-progress'

export default {
  name: "emailAddresses",
  props: {
    currentTask: Object,
  },
  components: {
    // VueJsProgress,
  },
  data() {
    return {
      // dataItems: [
      //     {
      //         name:'Google',
      //         amount:this.google(),
      //     },
      //     {
      //         name:'Outlook',
      //         amount:this.outlook(),
      //     },
      //     {
      //         name:'Yahoo',
      //         amount:this.yahoo(),
      //     },
      //     {
      //         name:'AOL',
      //         amount:this.aol(),
      //     },
      //     {
      //         name:'Company',
      //         amount:this.company(),
      //     },
      //     {
      //         name:'Unknown',
      //         amount:this.unknown(),
      //     },

      // ])
      series: [],
      chartOptions: {
        chart: {
          width: 380,
          type: "pie",
        },
        legend: {
          fontFamily: "Poppins",
          fontSize: "10px",
        },
        plotOptions: {
          pie: {
            dataLabels: {
              offset: -10,
            },
          },
        },
        stroke: {
          width: 0,
        },
        tooltip: {
          enabled: true,
          y: {
            formatter: function (val, opts) {
              let total = opts.globals.seriesTotals.reduce((a, b) => a + b, 0);
              let percentage = ((val / total) * 100).toFixed(0);
              return `${percentage}%`;
            },
          },
        },
        fill: {
          colors: [
            "#1ABC9C",
            "#F39C12",
            "#8E44AD",
            "#BDC3C7",
            "#2980B9",
            "#34495E",
            "#2C3E50",
            "#E74C3C",
            "#3498DB",
            "#8E44AD",
            "#E67E22",
            "#C0392B",
            "#3498DB",
            "#D35400",
          ],
        },
        labels: ["UK Landline", "UK Mobile", "UK Others", "International"],
        colors: [
          "#1ABC9C",
          "#F39C12",
          "#8E44AD",
          "#BDC3C7",
          "#2980B9",
          "#34495E",
          "#2C3E50",
          "#E74C3C",
          "#3498DB",
          "#8E44AD",
          "#E67E22",
          "#C0392B",
          "#3498DB",
          "#D35400",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val.toFixed(0) + "%";
          },
          style: {
            fontSize: "14px",
            fontWeight: 600,
            colors: ["#ffffff"],
          },
          dropShadow: {
            enabled: false,
          },
          textAnchor: "middle",
          offsetX: 5,
          offsetY: 0,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    showRecords() {
      var list = this.dataItems;
      //return list.sort((a, b) => (a.amount < b.amount) ? 1 : -1).slice(0, 6);
      return list;
    },
  },
  mounted() {
    this.calculatePhoneTypes();
  },
  methods: {
    calculatePhoneTypes() {
      var result = JSON.parse(this.currentTask.results);
      var totalRecords = parseInt(result.noOfPhoneRecords);

      // Calculate raw percentages
      let landline = (parseInt(result.noOFUKLandline) / totalRecords) * 100;
      let mobile = (parseInt(result.noOfUKMobile) / totalRecords) * 100;
      let others = (parseInt(result.noOfUKOthers) / totalRecords) * 100;
      let international =
        (parseInt(result.noOfInternationalTelephone) / totalRecords) * 100;

      // Normalize percentages to make sure the sum is 100%
      let totalPercentage = landline + mobile + others + international;

      if (totalPercentage !== 100) {
        // Adjust the largest value to make the sum 100%
        let maxValue = Math.max(landline, mobile, others, international);
        let adjustment = 100 - totalPercentage;

        if (maxValue === landline) landline += adjustment;
        else if (maxValue === mobile) mobile += adjustment;
        else if (maxValue === others) others += adjustment;
        else if (maxValue === international) international += adjustment;
      }

      // Set the series array
      this.series = [landline, mobile, others, international];
    },
    others() {
      var result = JSON.parse(this.currentTask.results);
      var totrec = result.noOfPhoneRecords;
      var others = (
        (parseInt(result.noOfUKOthers) / parseInt(totrec)) *
        100
      ).toFixed(0);
      return others;
    },
    landline() {
      var result = JSON.parse(this.currentTask.results);
      var totrec = result.noOfPhoneRecords;
      var landline = (
        (parseInt(result.noOFUKLandline) / parseInt(totrec)) *
        100
      ).toFixed(0);
      return landline;
    },
    mobile() {
      var result = JSON.parse(this.currentTask.results);
      var totrec = result.noOfPhoneRecords;
      var mobile = (
        (parseInt(result.noOfUKMobile) / parseInt(totrec)) *
        100
      ).toFixed(0);
      return mobile;
    },
    international() {
      var result = JSON.parse(this.currentTask.results);
      var totrec = result.noOfPhoneRecords;
      var international = (
        (parseInt(result.noOfInternationalTelephone) / parseInt(totrec)) *
        100
      ).toFixed(0);
      return international;
    },
  },
};
</script>

<style>
.emailAddresses-Container {
  min-height: 400px;
}

.emailAddresses-Title {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657d;
}

.emailAddresses-Count {
  font-size: 43px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
}

.emailAddresses-Summary {
  font-weight: 400;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  color: #3d415b;
}

.emailAddresses-Row {
  width: 100%;
  height: 39px;
  margin-top: 25px;
  margin-left: 10px;
}

.emailAddresses-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.emailAddresses-chartHolder {
  width: 40%;
  margin-left: 30%;
}

.emailAddresses-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.progress-bar {
  background-color: #147ad6 !important;
}

/* .apexcharts-tooltip.apexcharts-theme-dark {
  display: none;
} */
</style>
