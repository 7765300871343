<template>
  <div class="border-0">
    <div class="card-body">
      <div class="">
        <div class="d-flex flex-column text-left phoneTypes-Row">
          <div class="phoneTypes-titleHolder border-0">
            <span class="phoneTypes-Title">Title Breakdown</span>
            <span>
              <v-icon
                id="phoneTypes-help-icon"
                color="#2C9A65"
                small
                style="left: 8px"
                class="review-Helpbutton-Container"
                >mdi-information-outline</v-icon
              >
              <b-tooltip target="phoneTypes-help-icon" placement="bottom">
                A breakdown of the title provided within your contact data
              </b-tooltip></span
            >
          </div>
          <div id="chart">
            <apexchart
              type="bar"
              height="190"
              :options="chartOptions"
              :series="series"
            ></apexchart>
          </div>
          <!-- <div v-for="(item, i) in showRecords" v-bind:key="i">
                <b-row class="phoneTypes-Row">
                    <b-col>
                      <span class="show-percentage" style="float: right;">{{ item.amount }}%</span>
                      <VueJsProgress class="phoneTypes-Summary" :title=item.name  :percentage=item.amount customBgColor="#2C9A65"    :delay="600" :striped="true" show-text :animation="true"></VueJsProgress >
                    </b-col>
                  </b-row>
                </div> -->
          <!-- <b-col>
                        <span class="phoneTypes-Summary">{{item.name}}</span>
                    </b-col>
                    <b-col  style="text-align:right">
                        <span class="phoneTypes-Summary">{{item.amount}}</span>
                    </b-col> -->

          <!-- <b-row class="phoneTypes-Row">
                    <b-col>
                        <b-progress :value="item.amount" :max="maxRecords()" class="mb-3" style="background-color:rgba(115, 136, 169, 0.35);"></b-progress>
                    </b-col>
                </b-row> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import VueJsProgress from 'vue-js-progress'

export default {
  name: "phoneTypes",
  props: {
    currentTask: Object,
  },
  components: {
    // VueJsProgress,
  },
  data() {
    return {
      // dataItems: [
      //     {
      //         name:'Mobile',
      //         amount:this.mobile(),
      //     },
      //     {
      //         name:'Land Line',
      //         amount:this.landline(),
      //     },
      //     {
      //         name:'Other',
      //         amount:this.others(),
      //     },
      // ]
      series: [
        {
          name: "Count: ",
          data: [this.createdData(), this.refined(), this.blank()],
        },
      ],
      chartOptions: {
        chart: {
          height: 250,
          type: "bar",
          width: 100,
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "45%",
            distributed: true,
            dataLabels: {
              position: "top", // top, center, bottom
            },
          },
        },
        legend: {
          show: false,
          position: "right",
        },
        colors: ["#7F8C8D", "#3498DB", "#27AE60"],
        tooltip: {
          enabled: true,
          y: {
            formatter: (val, opts) => {
              const dataPointIndex = opts.dataPointIndex;
              if (dataPointIndex === 0) {
                return this.createdCount();
              } else if (dataPointIndex === 1) {
                return this.refinedCount();
              } else if (dataPointIndex === 2) {
                return this.blankcount();
              }
              return val;
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val + "%";
          },
          offsetY: -30,
          style: {
            fontSize: "14px",
            colors: ["#7F8C8D", "#3498DB", "#27AE60"],
          },
        },

        xaxis: {
          categories: ["Created", "Refined", "Blank or Null"],
          position: "bottom",
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          // crosshairs: {
          //   fill: {
          //     // type: 'gradient',
          //     gradient: {
          //       colorFrom: '#D8E3F0',
          //       colorTo: '#BED1E6',
          //       opacityFrom: 0.4,
          //       opacityTo: 0.2,
          //     }
          //   }
          // },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            // formatter: function (val) {
            //   return val + "%";
            // }
          },
        },
        // title: {
        //   text: 'Monthly Inflation in Argentina, 2002',
        //   floating: true,
        //   offsetY: 330,
        //   align: 'center',
        //   style: {
        //     color: '#444'
        //   }
        // }
      },
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    showRecords() {
      var list = this.dataItems;
      //return list.sort((a, b) => (a.amount < b.amount) ? 1 : -1).slice(0, 6);
      return list;
    },
  },
  mounted() {
    //this.fillData()
  },
  methods: {
    createdData() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var cnt = parseInt(currentTaskResults.noOfContactTitleCreated).toFixed(2);
      var total_count = parseInt(currentTaskResults.noOfRecords).toFixed(2);
      var retVal = ((cnt / total_count) * 100).toFixed(2);
      return retVal;
    },
    refined() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var cnt = parseInt(currentTaskResults.noOfContactTitleRefined).toFixed(2);
      var total_count = parseInt(currentTaskResults.noOfRecords).toFixed(2);
      var retVal = ((cnt / total_count) * 100).toFixed(2);
      return retVal;
    },
    blank() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var cnt = parseInt(currentTaskResults.noOfContactTitleBlank).toFixed(2);
      var total_count = parseInt(currentTaskResults.noOfRecords).toFixed(2);
      var retVal = ((cnt / total_count) * 100).toFixed(2);
      return retVal;
    },
    maxRecords() {
      var count = 0;
      this.dataItems.forEach((dataItem) => {
        count = count + parseInt(dataItem.amount);
      });
      return count;
    },
    createdCount() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var create = parseInt(currentTaskResults.noOfContactTitleCreated);

      return create;
    },
    refinedCount() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var create = parseInt(currentTaskResults.noOfContactTitleRefined);
      return create;
    },
    blankcount() {
      var currentTaskResults = JSON.parse(this.currentTask.results);
      var blank = parseInt(currentTaskResults.noOfContactTitleBlank);
      return blank;
    },
    // landline() {
    //   var currentTaskResults = JSON.parse(this.currentTask.results);
    //   var cnt = parseInt(currentTaskResults.noOfPhoneLandLine).toFixed(2);
    //   var total_count =
    //     parseInt(currentTaskResults.noOfPhoneLandLine) +
    //     parseInt(currentTaskResults.noOfPhoneMobile) +
    //     parseInt(currentTaskResults.noOfPhoneInValid);
    //   var retVal = ((cnt / total_count) * 100).toFixed(2);
    //   return retVal;
    // },
    // mobile() {
    //   var currentTaskResults = JSON.parse(this.currentTask.results);
    //   var cnt = parseInt(currentTaskResults.noOfPhoneMobile).toFixed(2);
    //   var total_count =
    //     parseInt(currentTaskResults.noOfPhoneLandLine) +
    //     parseInt(currentTaskResults.noOfPhoneMobile) +
    //     parseInt(currentTaskResults.noOfPhoneInValid);
    //   var retVal = ((cnt / total_count) * 100).toFixed(2);
    //   return retVal;
    // },
    // others() {
    //   var currentTaskResults = JSON.parse(this.currentTask.results);
    //   var cnt = parseInt(currentTaskResults.noOfPhoneInValid).toFixed(2);
    //   var total_count =
    //     parseInt(currentTaskResults.noOfPhoneLandLine) +
    //     parseInt(currentTaskResults.noOfPhoneMobile) +
    //     parseInt(currentTaskResults.noOfPhoneInValid);
    //   var retVal = ((cnt / total_count) * 100).toFixed(2);
    //   return retVal;
    // }
  },
};
</script>

<style>
.phoneTypes-Container {
  min-height: 200px;
}

.phoneTypes-Title {
  font-weight: 600;
  font-size: 17px;
  line-height: 22px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #62657d;
}

.phoneTypes-Count {
  font-size: 43px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  color: #3f6f86;
}

.phoneTypes-Summary {
  font-weight: 400;
  font-size: 12px;
  font-stretch: normal;
  font-style: normal;
  color: #3d415b;
}

.phoneTypes-Row {
  width: 100%;
}

.phoneTypes-Row-underline {
  border-bottom: solid 1px #e9eef3;
}

.phoneTypes-chartHolder {
  width: 40%;
  margin-left: 30%;
}

.phoneTypes-titleHolder {
  border-bottom: solid 1px #e9eef3;
  padding-bottom: 10px;
}

.progress-bar {
  background-color: #147ad6 !important;
}
</style>
